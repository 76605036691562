_context.invoke(function(Nittro) {
    var builder = new Nittro.DI.ContainerBuilder({
        "params": {
            "page": {
                "whitelistHistory": true,
                "whitelistLinks": true
            }
        },
        "extensions": {
            "ajax": "Nittro.Ajax.Bridges.AjaxDI.AjaxExtension()",
            "forms": "Nittro.Forms.Bridges.FormsDI.FormsExtension()",
            "page": "Nittro.Page.Bridges.PageDI.PageExtension()",
            "checklist": "Nittro.Extras.CheckList.Bridges.CheckListDI.CheckListExtension()",
            "dialogs": "Nittro.Extras.Dialogs.Bridges.DialogsDI.DialogsExtension()",
            "confirm": "Nittro.Extras.Confirm.Bridges.ConfirmDI.ConfirmExtension()",
            "dropzone": "Nittro.Extras.DropZone.Bridges.DropZoneDI.DropZoneExtension()"
        },
        "services": {},
        "factories": {}
    });

    this.di = builder.createContainer();
    this.di.runServices();

});